import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import ServicePage from "../../components/service";


export default function EnvironmentalMonitoringPage() {
    const {service} = useStaticQuery(graphql`
        query {
          service: servicesJson(key: {eq: "environmental-monitoring"}) {
            title
            title_long
            thumbnail {
              path {
                childImageSharp {
                  gatsbyImageData(width: 1024)
                }
              }
            }
            projects {
              title
              client
              location
            }
          }
        }
    `)
    return (
        <ServicePage service={service}>
            <p>
            REC has completed monitoring on federally and provincially regulated construction projects in sensitive terrestrial and aquatic habitats.  REC has demonstrated the ability to effectively work with contractors to ensure construction practices follow mitigations outlined in Construction Management Plans (CMPs), EPPs, and ESCs, as well as those set out by the regulator in order to minimize environmental impact.
            </p>
            <p className="mt-4">
            REC provides monitoring for construction projects including:
                <ul className="list-disc pl-8 mt-2">
                    <li>Water quality and flow monitoring</li>
                    <li>Fish rescues and water diversion</li>
                    <li>Water withdrawal and ice bridge construction monitoring</li>
                    <li>Wildlife pre- and post-disturbance monitoring</li>
                    <li>Spill prevention and mitigation</li>
                    <li>Post-construction monitoring (PCM) and oversight</li>
                    <li>Reclamation and ESC support and oversight</li>
                    <li>General environmental inspection</li>
                </ul>
            </p>
        </ServicePage>
    )
}
